import { Auth0Provider } from "@auth0/auth0-react";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Include only the reset
// import "instantsearch.css/themes/reset.css";
// or include the full Satellite theme
import "instantsearch.css/themes/satellite.css";
import { AfterLogin } from "./AfterLogin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/complete",
    element: <AfterLogin />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="mu-karte.jp.auth0.com"
      clientId="frhnRlYvaD9pLlS7W8oYK9PEKk1WOzO0"
      audience="https://mu-karte.forbital.com/api/"
      scope="read:records"
      redirectUri={`${window.location.origin}/complete`}
    >
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Notifications />
        <RouterProvider router={router} />
      </MantineProvider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
