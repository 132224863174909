import { useAuth0 } from "@auth0/auth0-react";
import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Card,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconAt, IconScript, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { GoPerson } from "react-icons/go";
import { HiAcademicCap } from "react-icons/hi";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { Highlight } from "react-instantsearch-hooks-web";
import useSWR, { useSWRConfig } from "swr";
import { useFetch } from "../hooks/useFetch";
import { KarteForm } from "./KarteForm";
import { Transcript } from "./Transcript";

export function Hit({ hit }: any) {
  const { user, getAccessTokenSilently } = useAuth0();
  const { fetch } = useFetch();
  const [name, setName] = useState<string>(hit.userId);
  const [editorOpened, setEditorVisibility] = useState(false);
  const [transcriptOpened, setTranscriptVisibility] = useState(false);

  useEffect(() => {
    const fn = async () => {
      const res = await fetch<{
        error: string | null;
        user: { name: string } | undefined;
      }>(`/api/users/${hit.userId}`);
      setName(res.user?.name!);
    };
    fn();
  }, [fetch, hit, getAccessTokenSilently]);

  const preStyle: React.CSSProperties = {
    fontFamily: "inherit",
    fontSize: "inherit",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  };

  // カルテ更新直前
  function onKarteWillUpdate(values: any, file: File | null) {
    const formData = new FormData();
    formData.append("form", JSON.stringify(values));

    if (file) formData.append("file", file);

    console.debug(formData);

    return fetch<{ error: string | null }>(`/api/questions/${hit.id}`, {
      method: "post",
      body: formData,
    });
  }

  function onKarteDidUpdated() {
    setEditorVisibility(false);
    window.location.reload();
  }

  async function onDelete() {
    const ok = prompt("削除の意思確認のためOKと記入してください");
    if (ok !== "OK") return;

    const res = await fetch<{ error: string | null }>(
      `/api/questions/${hit.id}`,
      {
        method: "delete",
      }
    );

    if (res.error) {
      return notifications.show({
        title: "Error",
        message: res.error,
      });
    }
    window.location.reload();
  }

  return (
    <Card
      shadow="sm"
      radius="md"
      withBorder
      style={{ border: hit.resolved ? "" : "2px solid red" }}
    >
      <Group mb="xs">
        {!hit.resolved && (
          <Badge color="red" variant="light">
            未解決
          </Badge>
        )}
        <Badge color="green" variant="light">
          <SiGoogleclassroom /> {hit.class}
        </Badge>
        <Badge color="pink" variant="light">
          <MdAssignmentTurnedIn />{" "}
          {hit.assignment === "none" ? "無し" : hit.assignment}
        </Badge>
        <Badge color="orange" variant="light">
          <GoPerson /> {hit.studentName}
        </Badge>
        <Badge color="cyan" variant="light">
          <HiAcademicCap /> {name}
        </Badge>
        <Badge color="gray" variant="light">
          <BsCalendar3 /> {new Date(hit.createdAt).toLocaleDateString()}
        </Badge>
        <ActionIcon
          variant="transparent"
          onClick={() => setTranscriptVisibility((prev) => !prev)}
          title="トランスクリプト"
          size={16}
        >
          <IconScript />
        </ActionIcon>
      </Group>
      <Group mt="xs" mb="xs">
        {hit.topic
          .filter((topic: string) => topic)
          .map((topic: any) => (
            <Badge
              color="blue"
              variant="light"
              key={topic}
              style={{ textTransform: "none" }}
            >
              {topic}
            </Badge>
          ))}
      </Group>
      <Text weight={600} size="lg">
        <pre style={preStyle}>
          Q. <Highlight attribute="question" hit={hit} />
        </pre>
      </Text>
      <Text>
        <pre style={preStyle}>
          A. <Highlight attribute="answer" hit={hit} />
        </pre>
      </Text>
      <Text size="sm" color="dimmed" mt="md">
        <pre style={preStyle}>{hit.note ? hit.note : "備考なし"}</pre>
      </Text>

      <Comments questionId={hit.id} />
      <Group>
        <CommentInput questionId={hit.id} />
        {user && user.sub === hit.userId && (
          <>
            <Modal
              opened={editorOpened}
              onClose={() => setEditorVisibility(false)}
              closeOnClickOutside={false}
              title="カルテの編集"
              size={600}
            >
              <KarteForm
                initialValues={{
                  createdAt: new Date(hit.createdAt),
                  studentId: hit.studentId,
                  studentName: hit.studentName,
                  class: hit.class,
                  assignment: hit.assignment,
                  topic: hit.topic,
                  question: hit.question,
                  answer: hit.answer,
                  note: hit.note,
                  resolved: hit.resolved,
                }}
                onSubmit={onKarteWillUpdate}
                onSuccess={onKarteDidUpdated}
              />
            </Modal>
            <Group>
              <Button
                onClick={() => {
                  console.log(hit);
                  setEditorVisibility(true);
                }}
                variant="light"
                color="blue"
                radius="md"
              >
                編集
              </Button>
              <Button
                onClick={onDelete}
                variant="outline"
                color="red"
                radius="md"
              >
                削除
              </Button>
            </Group>
          </>
        )}
      </Group>

      {transcriptOpened && <Transcript questionId={hit.id} />}
    </Card>
  );
}

function CommentInput({ questionId }: { questionId: string }) {
  const { fetch } = useFetch();
  const { mutate } = useSWRConfig();
  const [body, setBody] = useState<string>("");

  async function onUpdate() {
    const res = await fetch<{ error?: string }>(
      `/api/questions/${questionId}/comments`,
      {
        method: "post",
        body: JSON.stringify({ body }),
        headers: { "content-type": "application/json" },
      }
    );
    if (res.error) {
      console.log("commentError", res);
      notifications.show({
        title: "エラー",
        message: res.error,
        color: "red",
      });
      return;
    }
    console.debug("commentPost", res);
    setBody("");
    mutate(`/api/questions/${questionId}/comments`);
  }

  return (
    <Group sx={{ flexGrow: 1 }}>
      <TextInput
        icon={<IconAt />}
        style={{ flexGrow: 1 }}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <Button onClick={onUpdate}>コメント</Button>
    </Group>
  );
}

function Comments({ questionId }: { questionId: string }) {
  const { fetch } = useFetch();
  const { user } = useAuth0();
  const { data, mutate } = useSWR<{ error?: string; comments?: any[] }>(
    `/api/questions/${questionId}/comments`,
    fetch
  );

  async function deleteComment(commentId: string) {
    console.debug("deleting", commentId);
    const res = await fetch(
      `/api/questions/${questionId}/comments/${commentId}`,
      {
        method: "delete",
      }
    );
    console.debug("deleted", res);
    mutate();
  }

  return (
    <Stack my="xs" spacing="xs">
      {data?.comments?.map((comment) => (
        <Flex gap="xs" wrap="nowrap" key={comment.id} align="center">
          <>
            <Avatar
              src={comment.user.picture}
              alt={comment.user.name}
              size="sm"
            />
            <Text fz="sm" c="dimmed" sx={{ whiteSpace: "nowrap" }}>
              {comment.user.name}
            </Text>
          </>
          <Text fz="sm">{comment.body}</Text>
          {comment.user.id === user?.sub && (
            <ActionIcon size={16} onClick={() => deleteComment(comment.id)}>
              <IconTrash />
            </ActionIcon>
          )}
        </Flex>
      ))}
    </Stack>
  );
}
