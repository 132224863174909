import { Stack } from "@mantine/core";
import { UseHitsProps, useInfiniteHits } from "react-instantsearch-hooks-web";
import { Hit } from "./Hit";
import { useInteraction } from "../hooks/useInteraction";

// export type Hit = Speech & {
//   _highlightResult: { [id in keyof Speech]: { value: Speech[id] } };
//   _snippetResult: { [id in keyof Speech]: { value: Speech[id] } };
//   __position: number;
// };
export function HitList(props: UseHitsProps) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const [ref] = useInteraction<HTMLDivElement>(() => {
    showMore();
  }, [hits, showMore]);

  return (
    <Stack>
      {hits.map((hit) => (
        <Hit hit={hit} key={hit.__position}></Hit>
      ))}
      {!isLastPage && (
        <div style={{ visibility: "hidden" }} ref={ref}>
          Load more
        </div>
      )}
    </Stack>
  );
}
