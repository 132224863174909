import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mantine/core";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <Button onClick={() => loginWithRedirect()}>ログイン</Button>;
};

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      variant="default"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      ログアウト
    </Button>
  );
};
