import {
  Box,
  Button,
  Checkbox,
  FileInput,
  Group,
  MultiSelect,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { GoPerson } from "react-icons/go";
import { HiIdentification } from "react-icons/hi";
import { IoIosAdd } from "react-icons/io";
import { MdAssignmentTurnedIn } from "react-icons/md";
import {
  RiLightbulbFlashLine,
  RiPencilFill,
  RiQuestionAnswerFill,
  RiQuestionnaireFill,
} from "react-icons/ri";
import { SiGoogleclassroom } from "react-icons/si";
import { nonEmpty } from "../App";
import { useFetch } from "../hooks/useFetch";

function waterfall(fns: Function[]) {
  return (...args: any[]) => {
    for (const fn of fns) {
      const ret = fn(...args);
      if (ret !== null) return ret;
    }
    return null;
  };
}

export function KarteForm({
  onSuccess,
  initialValues,
  onSubmit,
}: {
  onSuccess: () => void;
  initialValues?: any;
  onSubmit: (values: any, file: File | null) => Promise<any>;
}) {
  const { fetch } = useFetch();
  const [isLoading, setLoading] = useState(false);
  const [existingTopics, setExistingTopics] = useState<string[]>([]);
  const [existingAssignments, setExistingAssignments] = useState<string[]>([]);
  const [existingClasses, setExistingClasses] = useState<string[]>([]);
  const [existingNames, setExistingNames] = useState<string[]>([]);

  const form = useForm({
    initialValues: initialValues ?? {
      createdAt: new Date(),
      studentId: "",
      studentName: "",
      class: "",
      assignment: "",
      topic: [] as string[],
      question: "",
      answer: "",
      note: "",
      resolved: false,
      file: null,
    },

    validate: {
      studentName: nonEmpty,
      assignment: nonEmpty,
      topic: waterfall([
        nonEmpty,
        (v: string[]) =>
          v.length < 2 ? "トピックを2つ以上選んでください" : null,
      ]),
      class: nonEmpty,
      question: nonEmpty,
      answer: nonEmpty,
    },
  });
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const promise = async () => {
      const ac = await fetch<{
        topics: string[];
        classes: string[];
        assignments: string[];
        names: string[];
      }>("/api/ac");

      setExistingTopics(ac.topics);
      setExistingClasses(ac.classes);
      setExistingAssignments(ac.assignments);
      setExistingNames(ac.names);
    };
    promise();
  }, [fetch]);

  async function onFormSubmit(values: typeof form.values) {
    setLoading(true);

    const response = await onSubmit(values, file);

    if (response.error) {
      notifications.show({
        title: "Error",
        message: response.error,
      });
      setLoading(false);
      return;
    }

    onSuccess();
  }

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
        <DatePickerInput
          placeholder="2022/02/22"
          label={
            <span>
              <BsCalendar3 /> 対応日
            </span>
          }
          withAsterisk
          {...form.getInputProps("createdAt")}
        />

        <Select
          label={
            <span>
              <GoPerson /> 学生名
            </span>
          }
          placeholder="柳田國男"
          data={Array.from(
            new Set([
              ...(form.values.studentName ? [form.values.studentName] : []),
              ...existingNames,
            ])
          )}
          searchable
          creatable
          getCreateLabel={(q) => `学生「${q}」を新規登録`}
          withAsterisk
          {...form.getInputProps("studentName")}
        />
        <TextInput
          label={
            <span>
              <HiIdentification /> 学生ID
            </span>
          }
          placeholder="42"
          {...form.getInputProps("studentId")}
        />
        <Group grow>
          <Select
            label={
              <span>
                <SiGoogleclassroom /> 科目名
              </span>
            }
            placeholder="2022プロプログラミング基礎"
            data={Array.from(
              new Set([
                ...(form.values.class ? [form.values.class] : []),
                ...existingClasses,
              ])
            )}
            searchable
            creatable
            getCreateLabel={(q) => `科目名「${q}」を新規作成`}
            withAsterisk
            {...form.getInputProps("class")}
          />

          <Select
            label={
              <span>
                <MdAssignmentTurnedIn /> 課題
              </span>
            }
            placeholder="アドバンスド1"
            data={Array.from(
              new Set([
                "無し",
                ...(form.values.assignment ? [form.values.assignment] : []),
                ...existingAssignments,
              ])
            )}
            searchable
            creatable
            getCreateLabel={(q) => `課題「${q}」を新規作成`}
            withAsterisk
            {...form.getInputProps("assignment")}
          />
        </Group>
        <MultiSelect
          label={
            <span>
              <RiLightbulbFlashLine /> トピック
            </span>
          }
          placeholder="条件分岐, 変数スコープ, 三角関数, Minecraft, etc"
          data={[...form.values.topic, ...existingTopics]}
          multiple
          searchable
          creatable
          getCreateLabel={(q) => `新トピック「${q}」を作成`}
          description="リストに合致するものが無い場合は、直に入力して新規作成"
          // onCreate={(q) => {
          //   console.log(q, form.values);
          //   form.setFieldValue("topic", [...form.values.topic, q]);
          //   return { label: q, value: q };
          // }}
          withAsterisk
          {...form.getInputProps("topic")}
        />
        <Textarea
          label={
            <span>
              <RiQuestionnaireFill /> 質問
            </span>
          }
          minRows={2}
          withAsterisk
          description="学生からの質問を要約する形で記載し、その内容を読めばどのような質問があったのかがわかるように記載してください。"
          {...form.getInputProps("question")}
        />
        <Textarea
          label={
            <span>
              <RiQuestionAnswerFill /> 回答
            </span>
          }
          description="学生が間違えていた部分と、それに対してどのように回答したのかについて記載してください。"
          {...form.getInputProps("answer")}
          withAsterisk
        />
        <Checkbox
          mt="sm"
          label="解決済み"
          {...form.getInputProps("resolved", { type: "checkbox" })}
        />
        <Textarea
          label={
            <span>
              <RiPencilFill /> 備考
            </span>
          }
          minRows={4}
          {...form.getInputProps("note")}
        />
        <FileInput
          placeholder="Transcript_7207ae22-5a85-4c1b-8bdf-819c1c328bb3.vtt"
          label="トランスクリプト"
          accept="text/vtt"
          value={file}
          onChange={setFile}
        />
        <Group position="right" mt="md">
          <Button type="submit" loading={isLoading} leftIcon={<IoIosAdd />}>
            送信
          </Button>
        </Group>
      </form>
    </Box>
  );
}
