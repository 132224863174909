import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import fetch from "cross-fetch";

export function useFetch() {
  const { getAccessTokenSilently } = useAuth0();

  const wrappedFetch = useCallback(
    async <T,>(input: RequestInfo | URL, init?: RequestInit) => {
      const token = await getAccessTokenSilently();
      const response = await fetch(input, {
        ...init,
        headers: { ...init?.headers, authorization: `Bearer ${token}` },
      }).then((res) => res.json());

      return response as T;
    },
    [getAccessTokenSilently]
  );

  return { fetch: wrappedFetch };
}
