import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Group,
  Loader,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useFetch } from "../hooks/useFetch";

export function Transcript({ questionId }: { questionId: number }) {
  const { getAccessTokenSilently } = useAuth0();
  const { fetch } = useFetch();
  const { data, isLoading } = useSWR<{ error?: string; transcript?: any }>(
    `/api/questions/${questionId}/transcript`,
    fetch
  );

  const [transcriptDownloadLink, setTranscriptDownloadLink] = useState<
    string | null
  >(null);

  // Fetch download link
  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      setTranscriptDownloadLink(
        `${window.location.origin}/api/questions/${questionId}/transcript/download?token=${token}`
      );
    };
    fn();
  }, [questionId, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <Stack mt="lg" spacing="xs">
        <Loader variant="bars" size="sm" />
      </Stack>
    );
  }

  return (
    <div>
      {data && data.transcript && (
        <Stack mt="lg" spacing="xs">
          {data.transcript.messages.map((cue: any, i: number) => (
            <Cue cue={cue} key={i} />
          ))}
        </Stack>
      )}
      {transcriptDownloadLink && (
        <a href={transcriptDownloadLink}>Download Transcript</a>
      )}
    </div>
  );
}

function Cue({ cue }: { cue: { speech: string; speaker: string } }) {
  const theme = useMantineTheme();
  const color = useMemo(() => {
    const keys = Object.keys(theme.colors);
    const shithash =
      cue.speaker
        .split("")
        .map((c) => c.charCodeAt(0))
        .reduce((s, c) => s + c, 0) % keys.length;
    const color = keys[shithash];
    return color;
  }, [cue.speaker, theme.colors]);

  return (
    <Group noWrap align="baseline" spacing="xs">
      <Avatar color={color} radius="sm" size="sm" variant="filled">
        {cue.speaker.charAt(0)}
      </Avatar>
      <Text size="sm">{cue.speech}</Text>
    </Group>
  );
}
