import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Flex, Group, Header } from "@mantine/core";
import { LogoutButton, LoginButton } from "./LoginButton";

export function GlobalHeader({ children }: { children?: React.ReactNode }) {
  const { user, isAuthenticated } = useAuth0();

  return (
    <Box pb="sm">
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          {children}

          <Group sx={{ height: "100%" }} spacing={0}>
            Web Karte
          </Group>

          <Group sx={{ height: "100%" }} spacing={0}>
            {isAuthenticated && user ? (
              <Group>
                <Flex align="center" mr="xs" gap="xs">
                  <Avatar src={user.picture} />
                  {user.name}
                </Flex>
                <LogoutButton />
              </Group>
            ) : (
              <LoginButton />
            )}
          </Group>
        </Group>
      </Header>
    </Box>
  );
}
