import { Group, Stack } from "@mantine/core";
import { InstantMeiliSearchInstance } from "@meilisearch/instant-meilisearch";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { RiLightbulbFlashLine } from "react-icons/ri";
import { SiGoogleclassroom } from "react-icons/si";
import {
  Configure,
  InstantSearch,
  RefinementList,
  SearchBox,
  SortBy,
} from "react-instantsearch-hooks-web";
import { HitList } from "./HitList";

export function Search({
  searchClient,
}: {
  searchClient: InstantMeiliSearchInstance;
}) {
  return (
    <InstantSearch indexName="q:createdAt:desc" searchClient={searchClient}>
      <Configure hitsPerPage={10} />

      <SearchBox />

      {/* <Menu attribute="class" showMore={true} /> */}

      <Group my="lg" align="flex-start">
        <Stack>
          <span>
            <SiGoogleclassroom /> 科目名
          </span>
          <RefinementList attribute="class" />
        </Stack>
        <Stack style={{ marginLeft: "30px" }}>
          <span>
            <MdAssignmentTurnedIn /> 課題
          </span>
          <RefinementList attribute="assignment" />
        </Stack>
        <Stack style={{ marginLeft: "30px" }}>
          <span>
            <RiLightbulbFlashLine /> トピック
          </span>
          <RefinementList attribute="topic" />
        </Stack>
      </Group>
      <Stack mb="sm">
        <SortBy
          items={[
            { label: "対応日(新しい順)", value: "q:createdAt:desc" },
            { label: "対応日(古い順)", value: "q:createdAt:asc" },
          ]}
        />
      </Stack>

      <HitList />
    </InstantSearch>
  );
}
