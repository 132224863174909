import { useAuth0 } from "@auth0/auth0-react";
import { Button, Group, Modal } from "@mantine/core";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { useState } from "react";
import "./App.css";
import { GlobalHeader } from "./components/GlobalHeader";
import { KarteForm } from "./components/KarteForm";
import { Search } from "./components/Search";
import { useFetch } from "./hooks/useFetch";

export const searchClient = instantMeiliSearch(
  window.location.origin,
  process.env.REACT_APP_MEILI_SEARCH_KEY
);

export default function App() {
  const { isAuthenticated } = useAuth0();
  const { fetch } = useFetch();

  const [opened, setOpened] = useState(false);

  // カルテ作成直前
  function onKarteWillCreate(values: any, file: File | null) {
    const formData = new FormData();
    formData.append("form", JSON.stringify(values));

    if (file) formData.append("file", file);

    console.debug(formData);

    return fetch<{ error: string | null }>("/api/questions", {
      method: "post",
      body: formData,
    });
  }

  // カルテ作成後
  function onKarteDidCreated() {
    setOpened(false);
    window.location.reload();
  }

  return (
    <div className="App">
      <GlobalHeader>
        {isAuthenticated && (
          <Group>
            <Button onClick={() => setOpened(true)}>作成</Button>
            <LinkToManual />
          </Group>
        )}
      </GlobalHeader>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        title="新規カルテの作成"
        size={600}
      >
        <KarteForm onSuccess={onKarteDidCreated} onSubmit={onKarteWillCreate} />
      </Modal>

      {isAuthenticated && (
        // https://github.com/meilisearch/instant-meilisearch/issues/527
        <Search searchClient={searchClient} />
      )}
    </div>
  );
}

function LinkToManual() {
  return (
    <a
      href="https://docs.u8.pub/s/47550e3a-925c-40ca-af98-bac074e31d76"
      target="_blank"
      rel="noopener noreferrer"
    >
      マニュアル(12/22更新)
    </a>
  );
}

export function nonEmpty(v: string | string[]) {
  return v.length === 0 ? "空白にしてはいけません" : null;
}
