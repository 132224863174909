import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function AfterLogin() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const authorized = useCallback(async () => {
    if (!isAuthenticated) return;

    const token = await getAccessTokenSilently();

    await fetch("/api/users", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    navigate("/");
  }, [user, isAuthenticated, getAccessTokenSilently, navigate]);

  useEffect(() => {
    authorized();
  }, [authorized]);

  return <></>;
}
